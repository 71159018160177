/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGlobalStore = defineStore('global', () => {
  const _disableKeyboardShortcuts = ref(false);
  const initializing = ref(true);

  function reset() {
    _disableKeyboardShortcuts.value = false;
  }

  function disableKeyboardShortcuts() {
    _disableKeyboardShortcuts.value = true;
  }

  function enableKeyboardShortcuts() {
    _disableKeyboardShortcuts.value = true;
  }

  function isKeyboardShortcutsEnabled() {
    return _disableKeyboardShortcuts.value === false;
  }

  return {
    initializing,
    reset,
    disableKeyboardShortcuts,
    enableKeyboardShortcuts,
    isKeyboardShortcutsEnabled,
  };
});
