import { boot } from 'quasar/wrappers';
import urql, { Client, ClientOptions, fetchExchange } from '@urql/vue';
import { useSettingsStore, useLoginStore } from 'src/stores';
import { authExchange } from '@urql/exchange-auth';
import axios from 'axios';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $gqlClient: Client;
  }
}

let gqlClient: Client;

export default boot(({ app, router, store }) => {
  const settingsStore = useSettingsStore(store);

  let clientConfig: ClientOptions;

  // token is because we were loaded from the Portal app
  if (settingsStore.token) {
    clientConfig = {
      url: `${settingsStore.baseGraphUrl}/graphql`,
      exchanges: [fetchExchange],
      fetchOptions: () => {
        const token = settingsStore.token;
        return {
          headers: { authorization: token ? `Bearer ${token}` : '' },
        };
      },
      requestPolicy: 'network-only',
    };
  } else {
    const loginStore = useLoginStore();

    clientConfig = {
      url: `${settingsStore.baseGraphUrl}/graphql`,
      exchanges: [
        authExchange(async (utils) => {
          return {
            addAuthToOperation(operation) {
              const accessToken = localStorage.getItem('accessToken');

              if (!accessToken) return operation;

              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${accessToken}`,
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            didAuthError(error, _operation) {
              let isAuthError = false;
              isAuthError = error.response.status === 401;
              if (!isAuthError) {
                isAuthError = error.message.includes('401');
              }
              return isAuthError;
            },
            async refreshAuth() {
              try {
                const refresh_token = localStorage.getItem('refreshToken');

                const response = await axios.get<{
                  access_token: string;
                  token_type: string;
                  expires_in: number;
                }>(`${settingsStore.baseGraphUrl}/api/auth/refreshToken`, {
                  params: { refresh_token: refresh_token },
                });

                localStorage.setItem('accessToken', response.data.access_token);
              } catch (e) {
                loginStore.logout();
                router.push({ path: '/login', replace: true });
              }
            },
          };
        }),
        fetchExchange,
      ],
      requestPolicy: 'network-only',
    };
  }

  gqlClient = new Client(clientConfig);

  app.config.globalProperties.$gqlClient = gqlClient;
  app.use(urql, clientConfig);
});

export { gqlClient };
