/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type DeleteUserDocumentByIdInput = {
  documentId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type DeleteUserDocumentByIdResponse = {
  __typename?: 'DeleteUserDocumentByIdResponse';
  success: Scalars['Boolean']['output'];
};

export type Document = {
  __typename?: 'Document';
  documentSignedUrl: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
  documentTypeId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  s3DocumentName: Scalars['String']['output'];
  searchId: Scalars['String']['output'];
  uploadedDate: Scalars['String']['output'];
};

/** Represents an expired immunization for a user */
export type ExpiredImmunization = {
  __typename?: 'ExpiredImmunization';
  /** The count of days that the last administered immunization has been expired */
  daysExpired: Scalars['Int']['output'];
  /** The date when the last administered immunization expired */
  expirationDate: Scalars['DateTime']['output'];
  /** The ID for an immunity */
  immunityId: Scalars['Int']['output'];
  /** When the immunization was last administered */
  immunizationDate: Scalars['DateTime']['output'];
  /** The UserId of the user */
  userId: Scalars['Int']['output'];
};

export type Institution = {
  __typename?: 'Institution';
  expiredImmunizations: Array<ExpiredImmunization>;
  /** Global Unique ID */
  id: Scalars['Int']['output'];
  /** Institutions Name */
  name: Scalars['String']['output'];
  /** Reports */
  reports: Reports;
  requirementGroups: Array<RequirementGroup>;
  /** Active Users */
  users: Array<User>;
};


export type InstitutionUsersArgs = {
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteUserDocumentById: DeleteUserDocumentByIdResponse;
  /** Delete a Report by Report ID */
  reportsDelete: Scalars['Boolean']['output'];
  /** Save a Report */
  reportsSave: ReportsSaveResult;
  /** Delete a Report by Report ID */
  reportsScheduleUpdate: Scalars['Boolean']['output'];
  updateDocument: UpdateDocumentResponse;
  uploadUserDocument: UploadUserDocumentResponse;
};


export type MutationDeleteUserDocumentByIdArgs = {
  input: DeleteUserDocumentByIdInput;
};


export type MutationReportsDeleteArgs = {
  name: Scalars['String']['input'];
};


export type MutationReportsSaveArgs = {
  input: ReportDefinitionInput;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationReportsScheduleUpdateArgs = {
  input: ReportScheduleUpdateInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUploadUserDocumentArgs = {
  input: UploadUserDocumentInput;
};

export type Pyramed = {
  __typename?: 'Pyramed';
  students: Array<PyramedStudent>;
};

export type PyramedDocument = {
  __typename?: 'PyramedDocument';
  documentsignedurl: Scalars['String']['output'];
  documenttype?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
};

export type PyramedEmergencyContact = {
  __typename?: 'PyramedEmergencyContact';
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
};

export type PyramedImmunization = {
  __typename?: 'PyramedImmunization';
  immunizationdate: Scalars['DateTime']['output'];
  immunizationname: Scalars['String']['output'];
  isdocumentation?: Maybe<Scalars['Boolean']['output']>;
  istiter?: Maybe<Scalars['Boolean']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  titervalue?: Maybe<Scalars['String']['output']>;
};

export type PyramedStudent = {
  __typename?: 'PyramedStudent';
  Documents: Array<PyramedDocument>;
  EmergencyContact: PyramedEmergencyContact;
  Immunizations: Array<PyramedImmunization>;
  Profile: PyramedStudentProfile;
  TbQuestionnaire: PyramedTbQuestionnaire;
  medproctorid: Scalars['Int']['output'];
  studentid?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<UserTag>>;
  username: Scalars['String']['output'];
  verifydate?: Maybe<Scalars['DateTime']['output']>;
};

export type PyramedStudentProfile = {
  __typename?: 'PyramedStudentProfile';
  Firstname?: Maybe<Scalars['String']['output']>;
  Lastname?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  middlename?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type PyramedTbQuestionnaire = {
  __typename?: 'PyramedTbQuestionnaire';
  TBCountry?: Maybe<Scalars['String']['output']>;
  TbArrivedInUSWithin5Years?: Maybe<Scalars['Boolean']['output']>;
  TbBCG?: Maybe<Scalars['Boolean']['output']>;
  TbBCGDate?: Maybe<Scalars['DateTime']['output']>;
  TbHighRiskContinentBorn?: Maybe<Scalars['Boolean']['output']>;
  TbHighRiskContinentTraveled?: Maybe<Scalars['Boolean']['output']>;
  TbHighRiskVolunteer?: Maybe<Scalars['Boolean']['output']>;
  TbLatentInfectionOrDisease?: Maybe<Scalars['Boolean']['output']>;
  TbOnImmunosuppressant?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve the MedProctor Institutions Object. id is optional. If id is not provided then the current user's institution will be returned. If id is provided then it will be returned only if the user has authority. */
  institution?: Maybe<Institution>;
  pyramed: Pyramed;
  pyramedByInstitution: Pyramed;
  /** Date Point Reporting */
  reports: Reports;
  /** User Query Resolver.  Available only for MedProctor Admins. */
  user?: Maybe<User>;
};


export type QueryInstitutionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPyramedArgs = {
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  pageNumber: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
  verifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPyramedByInstitutionArgs = {
  institutionID: Scalars['Float']['input'];
  modified?: InputMaybe<Scalars['DateTime']['input']>;
  pageNumber: Scalars['Float']['input'];
  pageSize: Scalars['Float']['input'];
  verifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReportsArgs = {
  institutionId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ReportDataPoint = {
  __typename?: 'ReportDataPoint';
  displayName?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

/** The columns used in a report */
export type ReportDataPointDefinition = {
  __typename?: 'ReportDataPointDefinition';
  /** If Computed it is set by a script */
  computed?: Maybe<Scalars['Boolean']['output']>;
  /** Overrides the report date format. */
  dateFormat?: Maybe<ReportsDateFormatEnum>;
  /** The description of the dataPoint */
  description: Scalars['String']['output'];
  /** Column name in the report. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Hide this dataPoint. */
  hidden?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the Data Point or Computed Name */
  name: Scalars['String']['output'];
  /** The source of the dataPoint */
  source: ReportsDataPointSourceEnum;
  /** The data type of the data point */
  type: ReportsDataPointTypeEnum;
};

export type ReportDataPointInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

/** The definition of an adhoc report */
export type ReportDataSetInput = {
  /** The format of the JSON results */
  dataSetFormat?: InputMaybe<ReportsDataSetFormatEnum>;
  /** If `name` empty or null then perform the report based on the definition */
  definition?: InputMaybe<ReportDefinitionInput>;
  /** Name of the Report */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Starting Page */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Page Size */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** The Data Set of a Report */
export type ReportDataSetResult = {
  __typename?: 'ReportDataSetResult';
  /** The total count of records before paging */
  count: Scalars['Int']['output'];
  /** The report's data set */
  dataSet: Scalars['Any']['output'];
  /** Script Logs */
  logs?: Maybe<Array<Scalars['String']['output']>>;
};

export type ReportDefinition = {
  __typename?: 'ReportDefinition';
  apiVersion?: Maybe<Scalars['String']['output']>;
  dataPoints: Array<ReportDataPoint>;
  dateFormat?: Maybe<Scalars['String']['output']>;
  institutionId?: Maybe<Scalars['Int']['output']>;
  logLevel?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onFetch?: Maybe<Scalars['String']['output']>;
  orderBy?: Maybe<Array<Scalars['String']['output']>>;
  requirementGroup?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<ReportSchedule>;
  term?: Maybe<Scalars['String']['output']>;
  unpivotDataPoints?: Maybe<ReportUnpivot>;
  verifiedOnly: Scalars['Boolean']['output'];
};

export type ReportDefinitionInput = {
  apiVersion?: InputMaybe<Scalars['String']['input']>;
  dataPoints: Array<ReportDataPointInput>;
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  institutionId?: InputMaybe<Scalars['Int']['input']>;
  logLevel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onFetch?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<Scalars['String']['input']>>;
  requirementGroup?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  unpivotDataPoints?: InputMaybe<ReportUnpivotInput>;
  verifiedOnly: Scalars['Boolean']['input'];
};

/** Header of a Report.  Typically used for listing the available reports in an UI. */
export type ReportHeader = {
  __typename?: 'ReportHeader';
  /** Is this report a template? */
  isTemplate: Scalars['Boolean']['output'];
  /** Name of the report */
  name: Scalars['String']['output'];
};

export type ReportSchedule = {
  __typename?: 'ReportSchedule';
  cron: Scalars['String']['output'];
  delimiter: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  filenameTemplate: Scalars['String']['output'];
  preventEmptyFiles: Scalars['Boolean']['output'];
  removeHeader: Scalars['Boolean']['output'];
};

export type ReportScheduleUpdateInput = {
  cron: Scalars['String']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filenameTemplate: Scalars['String']['input'];
  name: Scalars['String']['input'];
  preventEmptyFiles?: InputMaybe<Scalars['Boolean']['input']>;
  removeHeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportUnpivot = {
  __typename?: 'ReportUnpivot';
  dataPointNames: Array<Scalars['String']['output']>;
  keyName: Scalars['String']['output'];
  valueName: Scalars['String']['output'];
};

export type ReportUnpivotInput = {
  dataPointNames: Array<Scalars['String']['input']>;
  keyName: Scalars['String']['input'];
  valueName: Scalars['String']['input'];
};

/** The is the high level interface to work with Reports */
export type Reports = {
  __typename?: 'Reports';
  /** List of data points that can be used in a report */
  dataPoints: Array<ReportDataPointDefinition>;
  /** Returns a data set, based on a dynamic report definition */
  dataSet: ReportDataSetResult;
  /** Retrieve a report object */
  definition?: Maybe<ReportDefinition>;
  /** This list of reports */
  list: Array<ReportHeader>;
  /** The schedule for a report */
  schedule: ReportSchedule;
};


/** The is the high level interface to work with Reports */
export type ReportsDataSetArgs = {
  input: ReportDataSetInput;
};


/** The is the high level interface to work with Reports */
export type ReportsDefinitionArgs = {
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};


/** The is the high level interface to work with Reports */
export type ReportsScheduleArgs = {
  name: Scalars['String']['input'];
};

/** The source of a data point */
export enum ReportsDataPointSourceEnum {
  Documents = 'DOCUMENTS',
  Immunities = 'IMMUNITIES',
  Insurance = 'INSURANCE',
  MedicalId = 'MEDICAL_ID',
  Misc = 'MISC',
  Programs = 'PROGRAMS',
  Tags = 'TAGS',
  Tb = 'TB',
  TbQuestionnaires = 'TB_QUESTIONNAIRES',
  Titers = 'TITERS',
  Tokens = 'TOKENS',
  User = 'USER'
}

/** The type of the value of a data point */
export enum ReportsDataPointTypeEnum {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Number = 'NUMBER',
  String = 'STRING'
}

/** The format of the data set row */
export enum ReportsDataSetFormatEnum {
  /** As an array of objects.  The properties are unordered */
  Properties = 'PROPERTIES',
  /** Only the values in an array in the order of the defined data set report */
  ValueArray = 'VALUE_ARRAY'
}

/** Date Formats for the Date typed Data Points */
export enum ReportsDateFormatEnum {
  /** ANSIC - Wed May  3 20:52:51 2023 */
  Ansic = 'ANSIC',
  /** DATEONLY - 2023-05-03 */
  Dateonly = 'DATEONLY',
  /** DATETIME - 2023-05-03 20:52:51 */
  Datetime = 'DATETIME',
  /** ISO - 2023-05-03 */
  Iso = 'ISO',
  /** RFC822 - 03 May 23 20:52 UTC */
  Rfc822 = 'RFC822',
  /** RFC822Z - 03 May 23 20:52 +0000 */
  Rfc822Z = 'RFC822Z',
  /** RFC850 - Wednesday, 03-May-23 20:52:51 UTC */
  Rfc850 = 'RFC850',
  /** RFC1123 - Wed, 03 May 2023 20:52:51 UTC */
  Rfc1123 = 'RFC1123',
  /** RFC1123Z - Wed, 03 May 2023 20:52:51 +0000 */
  Rfc1123Z = 'RFC1123Z',
  /** RFC3339 - 2023-05-03T20:52:51Z */
  Rfc3339 = 'RFC3339',
  /** RFC3339NANO - 2023-05-03T20:52:51.896Z */
  Rfc3339Nano = 'RFC3339NANO',
  /** RUBYDATE - Wed May 03 20:52:51 +0000 2023 */
  Rubydate = 'RUBYDATE',
  /** STAMP - May  3 20:52:51 */
  Stamp = 'STAMP',
  /** STAMPMICRO - May  3 20:52:51.896000 */
  Stampmicro = 'STAMPMICRO',
  /** STAMPMILLI - May  3 20:52:51.896 */
  Stampmilli = 'STAMPMILLI',
  /** STAMPNANO - May  3 20:52:51.896000000 */
  Stampnano = 'STAMPNANO',
  /** UNIXEPOCH - 1683147171 */
  Uniuxepoch = 'UNIUXEPOCH',
  /** UNIXDATE - Wed May  3 20:52:51 UTC 2023 */
  Unixdate = 'UNIXDATE',
  /** USA - 05/03/2023 */
  Usa = 'USA'
}

/** The results when saving a report */
export type ReportsSaveResult = {
  __typename?: 'ReportsSaveResult';
  /** A new ID or the updated ID */
  id: Scalars['String']['output'];
};

export type RequirementGroup = {
  __typename?: 'RequirementGroup';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  terms?: Maybe<Array<Term>>;
};

export type Term = {
  __typename?: 'Term';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateDocumentInput = {
  documentId: Scalars['String']['input'];
  institutionId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  userName: Scalars['String']['input'];
};

export type UpdateDocumentResponse = {
  __typename?: 'UpdateDocumentResponse';
  success: Scalars['Boolean']['output'];
};

export type UploadUserDocumentInput = {
  base64Document: Scalars['String']['input'];
  documentTypeId: Scalars['Int']['input'];
  originalFileName: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type UploadUserDocumentResponse = {
  __typename?: 'UploadUserDocumentResponse';
  id: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  /** The list of documents for the user */
  documents: Array<Document>;
  /** The list of expired immunizations based on that's required in the institutionSchoolId */
  expiredImmunizations: Array<ExpiredImmunization>;
  /** Global Unique ID */
  id: Scalars['ID']['output'];
  /** The immunizations the user has registered */
  immunizations: Array<UserImmunization>;
  /** The Institutions the user is registered */
  institutionId?: Maybe<Scalars['Int']['output']>;
  /** The school the user is registered */
  institutionSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The school period the user is registered */
  institutionSchoolPeriodId?: Maybe<Scalars['Int']['output']>;
  /** The student id of the user */
  studentId?: Maybe<Scalars['String']['output']>;
  /** The list of tag associated to a user */
  tags?: Maybe<Array<UserTag>>;
  /** MedProctor ID */
  userId: Scalars['Int']['output'];
  /** User name */
  userName: Scalars['String']['output'];
};

export type UserImmunization = {
  __typename?: 'UserImmunization';
  dates: Array<UserImmunizationDate>;
  immunityId: Scalars['Int']['output'];
  immunityName: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
};

export type UserImmunizationDate = {
  __typename?: 'UserImmunizationDate';
  date?: Maybe<Scalars['DateTime']['output']>;
  dateType: Scalars['String']['output'];
  titerValue: Scalars['String']['output'];
};

export type UserTag = {
  __typename?: 'UserTag';
  created: Scalars['DateTime']['output'];
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  tagName: Scalars['String']['output'];
};
