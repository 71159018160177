/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  IUsableColumnType,
  useReportStore,
  useSettingsStore,
  useUsableColumnTypesStore,
} from 'src/stores';
import { ReportsGraphqlClient } from 'src/services/graphqlClients';
import {
  ReportDataPoint,
  ReportDataPointInput,
  ReportDefinition,
  ReportDefinitionInput,
  ReportHeader,
  ReportSchedule,
  ReportScheduleUpdateInput,
  ReportsDataPointSourceEnum,
  ReportsDataPointTypeEnum,
  ReportsDateFormatEnum,
  ReportUnpivot,
} from 'src/gql/graphql';
import { ref } from 'vue';

export const usePersistenceStore = defineStore('persistence', () => {
  const dateFormat = ref(ReportsDateFormatEnum.Usa);
  const id = ref('');
  const name = ref('Enter report name');
  const onFetch = ref<string | undefined>(undefined);
  const requirementGroup = ref(<string | undefined>undefined);
  const term = ref(<string | undefined>undefined);
  const verifiedOnly = ref(false);
  const dataPoints = ref<ReportDataPoint[]>([]);
  const dataSet = ref(undefined);
  const count = ref(0);
  const orderBy = ref(<string[] | undefined>undefined);
  const unpivotDataPoints = ref(<ReportUnpivot | undefined>undefined);

  const columns = ref(<IUsableColumnType[]>[]);

  const reportStore = useReportStore();
  const settingsStore = useSettingsStore();
  const usableColumnTypesStore = useUsableColumnTypesStore();

  const fetching = ref(false);
  const saving = ref(false);

  function reset() {
    columns.value.splice(0);
    id.value = '';
    name.value = '';
    dateFormat.value = ReportsDateFormatEnum.Usa;
    dataPoints.value.splice(0);
    dataSet.value = undefined;
    count.value = 0;
    requirementGroup.value = '';
    term.value = '';
    onFetch.value = undefined;
    verifiedOnly.value = false;
    orderBy.value = undefined;
    unpivotDataPoints.value = undefined;

    fetching.value = false;
    saving.value = false;
  }

  function isDirty(): boolean {
    if (
      reportStore.originalOnFetchScript !== reportStore.modifiedOnFetchScript
    ) {
      return true;
    }

    if (dataPoints.value.length !== columns.value.length) {
      return true;
    }

    if (columns.value.length > 0) {
      for (let i = 0; i < dataPoints.value.length; i++) {
        if (dataPoints.value[i].name !== columns.value[i].name) {
          return true;
        }
      }
    }

    // if (reportDefinition.value.name != reportStore.name) {
    //   return true;
    // }

    if (verifiedOnly.value != reportStore.verifiedOnly) {
      return true;
    }

    if (requirementGroup.value != reportStore.requirementGroup) {
      return true;
    }

    if (term.value != reportStore.term) {
      return true;
    }

    return (
      dateFormat.value.trim().toUpperCase() !=
      reportStore.dateFormat.trim().toUpperCase()
    );
  }

  async function newReport() {
    reset();
    usableColumnTypesStore.reset();
    reportStore.reset();

    await usableColumnTypesStore.fetchUsableColumnTypes();
  }

  async function deleteActiveReport(): Promise<boolean> {
    const gqlClient = new ReportsGraphqlClient();
    const didItWork = await gqlClient.delete(name.value);
    if (didItWork) {
      await newReport();
    }

    return didItWork;
  }

  async function schedule(): Promise<ReportSchedule | undefined> {
    const gqlClient = new ReportsGraphqlClient();
    const sch = await gqlClient.schedule(name.value);
    if (!sch) {
      return undefined;
    }

    return sch;
  }

  async function updateSchedule(
    reportScheduleUpdateInput: ReportScheduleUpdateInput,
  ): Promise<boolean> {
    const gqlClient = new ReportsGraphqlClient();
    const success = await gqlClient.updateSchedule(reportScheduleUpdateInput);
    if (success === undefined) {
      return false;
    }

    return success;
  }

  async function fetchReportHeaders(): Promise<ReportHeader[]> {
    fetching.value = true;

    try {
      const graphqlClient = new ReportsGraphqlClient();
      return await graphqlClient.headers();
    } finally {
      fetching.value = false;
    }
  }

  async function openReport(nameOfReport: string) {
    fetching.value = true;

    try {
      const graphqlClient = new ReportsGraphqlClient();
      const report =
        (await graphqlClient.definition(nameOfReport)) ??
        ({} as ReportDefinition);

      const reportDateFormat = report.dateFormat?.toUpperCase() ?? 'USA';
      reportStore.dateFormat = <ReportsDateFormatEnum>reportDateFormat;

      reportStore.name = report.name ?? '';
      reportStore.requirementGroup = report.requirementGroup ?? undefined;
      reportStore.term = report.term ?? undefined;
      reportStore.verifiedOnly = report.verifiedOnly ?? false;
      reportStore.modifiedOnFetchScript = report.onFetch ?? '';
      reportStore.originalOnFetchScript = report.onFetch ?? '';
      reportStore.unpivotDataPoints = report.unpivotDataPoints ?? undefined;
      reportStore.orderBy = report.orderBy ?? undefined;
      reportStore.schedule = report.schedule ?? undefined;

      dateFormat.value = reportStore.dateFormat;
      name.value = reportStore.name;
      onFetch.value = report.onFetch ?? undefined;
      requirementGroup.value = report.requirementGroup ?? '';
      term.value = report.term ?? '';
      verifiedOnly.value = report.verifiedOnly ?? false;
      unpivotDataPoints.value = report.unpivotDataPoints ?? undefined;
      orderBy.value = report.orderBy ?? undefined;

      // await usableColumnTypesStore.fetchUsableColumnTypes();

      // now build the column stuff
      // the usableColumnTypesStore as all the columns.
      // we have the ID's for the report.
      // for each report ID remove from the column types and add it to the report column.

      columns.value.splice(0);

      usableColumnTypesStore.$patch((state) => {
        for (let ii = 0; ii < state.usableColumnTypes.length; ii++) {
          const columnType = state.usableColumnTypes[ii];
          columnType.removed = false;
        }
      });

      // this is some very bad big O looping, but it works, and it seems to be fast enough in
      // the modern browser and modern hardware
      usableColumnTypesStore.$patch((usableColumnTypeState) => {
        report.dataPoints.forEach((dataPoint) => {
          let usableColumnType: IUsableColumnType | undefined = undefined;

          for (
            let i = 0;
            i < usableColumnTypeState.usableColumnTypes.length;
            i++
          ) {
            //usableColumnType = usableColumnTypeState.usableColumnTypes[i];

            if (
              usableColumnTypeState.usableColumnTypes[i].name.toLowerCase() ===
              dataPoint.name.toLowerCase()
            ) {
              usableColumnType = usableColumnTypeState.usableColumnTypes[i];
              break;
            }
          }

          if (usableColumnType !== undefined) {
            usableColumnType.removed = true;
            usableColumnType.visible = true;
            usableColumnType.displayName = dataPoint.displayName;
            usableColumnType.hidden = dataPoint.hidden;
            columns.value.push(usableColumnType);
          } else {
            usableColumnType = <IUsableColumnType>{};
            usableColumnType.visible = true;
            usableColumnType.removed = true;
            usableColumnType.name = dataPoint.name;
            usableColumnType.type = ReportsDataPointTypeEnum.String;
            usableColumnType.source = <ReportsDataPointSourceEnum>'MISC';
            usableColumnType.description = 'Dynamic Data Point';
            usableColumnType.hidden = false;
            usableColumnType.computed = true;
            columns.value.push(usableColumnType);
          }
        });
      });

      await reportStore.rebuildTable();
    } finally {
      fetching.value = false;
    }
  }

  async function saveReport(
    name: string,
    forceOverwrite = false,
    existingDefinition?: ReportHeader,
  ): Promise<void> {
    saving.value = true;

    const report = {} as ReportDefinitionInput;
    report.apiVersion = '1.0';
    report.institutionId = settingsStore.institutionId;
    report.name = name.trim();
    report.logLevel = '';
    report.onFetch = reportStore.modifiedOnFetchScript;
    report.requirementGroup = reportStore.requirementGroup ?? '';
    report.term = reportStore.term ?? '';
    report.verifiedOnly = reportStore.verifiedOnly;
    report.dateFormat = reportStore.dateFormat;
    report.orderBy = reportStore.orderBy;
    report.unpivotDataPoints = reportStore.unpivotDataPoints;

    report.dataPoints = [];

    columns.value.forEach((column) => {
      const dataPoint: ReportDataPointInput = {
        name: column.name,
        hidden: column.hidden,
        displayName: column.displayName,
      };
      report.dataPoints.push(dataPoint);
    });

    if (forceOverwrite) {
      report.name = <string>existingDefinition?.name;
    }

    let reportId: string | undefined;

    try {
      const gqlClient = new ReportsGraphqlClient();
      reportId = await gqlClient.save(report);
      id.value = <string>reportId;
      reportStore.originalOnFetchScript = reportStore.modifiedOnFetchScript;
    } finally {
      saving.value = false;
    }
  }

  return {
    dateFormat,
    id,
    name,
    onFetch,
    requirementGroup,
    term,
    verifiedOnly,
    dataPoints,
    dataSet,
    count,
    fetching,
    saving,
    reset,
    isDirty,
    newReport,
    deleteActiveReport,
    fetchReportHeaders,
    openReport,
    saveReport,
    columns,
    orderBy,
    unpivotDataPoints,
    schedule,
    updateSchedule,
  };
});
