import { route } from 'quasar/wrappers';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes, { LOGIN_PAGE } from './routes';
import { useLoginStore, useSettingsStore } from 'src/stores';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const settingsStore = useSettingsStore();
  const loginStore = useLoginStore();

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalizedLoaded,
      next: NavigationGuardNext
    ) => {
      if (settingsStore.isStandalone()) {
        if (
          to.matched.some((record) => record.meta.requiresAuth) &&
          !loginStore.isLoggedIn()
        ) {
          next({ name: LOGIN_PAGE, query: { next: to.fullPath } });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  );

  return Router;
});
