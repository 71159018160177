import { RouteRecordRaw } from 'vue-router';

export const ROOT_PAGE = '/';
//export const REPORTS_PAGE = '/reports';
export const LOGIN_PAGE = '/login';

const isStandalone = !window.hasOwnProperty('c');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    //    component: () => import('layouts/DashboardLayout.vue'),
    component: () => {
      let layout: any;
      if (isStandalone) {
        layout = import('layouts/DashboardLayout.vue');
      } else {
        layout = import('layouts/MainLayout.vue');
      }
      return layout;
    },
    children: [
      {
        path: '',
        name: ROOT_PAGE,
        // component: () => import('pages/IndexPage.vue'),
        component: () => import('pages/reports/IndexPage.vue'),
      },
      // {
      //   path: '/reports',
      //   name: REPORTS_PAGE,
      //   component: () => import('pages/reports/IndexPage.vue'),
      // },
    ],
  },
];

if (isStandalone) {
  routes.push({
    path: '/login',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        name: LOGIN_PAGE,
        component: () => import('pages/LoginPage.vue'),
      },
    ],
  });
}

routes.push(
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  }
);

export default routes;
