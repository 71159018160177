import { defineStore } from 'pinia';
import CryptoJS from 'crypto-js';
import { computed, ref } from 'vue';

export const useSettingsStore = defineStore('settings', () => {
  const domain = ref('secure.medproctor.com');
  const isProduction = ref(false);
  const isDemo = ref(false);
  const isAccount = ref(false);
  const telemetryKey = ref('');
  const isAdmin = ref(false);
  const configMenu = ref(false);
  const roles = ref(<string[]>[]);
  const token = ref('');
  const accessToken = ref('');
  const institutionId = ref<number>();
  const baseGraphUrl = ref(getBaseGraphUrl());

  const isInRole = computed(() => {
    return (role: string) => roles.value.includes(role);
  });

  function getBaseGraphUrl(): string {
    const hasBaseGraphUrl = window.hasOwnProperty('base_graph_url');
    if (hasBaseGraphUrl) {
      return base_graph_url ?? 'https://graph.medproctor.com';
    } else {
      return process.env.DEV
         ? 'http://localhost:8080'
         : 'https://graph.medproctor.com';
    }
  }

  function isStandalone(): boolean {
    return !window.hasOwnProperty('c');
  }

  function decrypt(payload?: string) {
    if (!payload) return;

    const obj = decryptInitialState(payload);

    for (let i = 0; i < obj.roles.length; i++) {
      roles.value.push(obj.roles[i]);
    }

    configMenu.value = obj.configMenu;
    isAdmin.value = obj.isAdmin;

    domain.value = obj.domain;
    institutionId.value = obj.institutionId;
    isProduction.value = obj.isProduction;
    isDemo.value = obj.isDemo;
    isAccount.value = obj.isAccount;
    telemetryKey.value = obj.telemetryKey;
    token.value = obj.token;
    baseGraphUrl.value = obj.baseGraphUrl;
  }

  return {
    domain,
    isProduction,
    isDemo,
    isAccount,
    telemetryKey,
    isAdmin,
    configMenu,
    roles,
    token,
    accessToken,
    baseGraphUrl,
    isInRole,
    institutionId,
    isStandalone,
    decrypt,
  };
});

export interface InitialState {
  roles: string[];
  domain: string;
  isProduction: boolean;
  isDemo: boolean;
  isAccount: boolean;
  telemetryKey: string;
  isAdmin: boolean;
  configMenu: boolean;
  token: string;
  baseGraphUrl: string;
  institutionId: number;
}

export function decryptInitialState(payload: string): InitialState {
  const AesKey = CryptoJS.enc.Utf8.parse('9nxhvVUk0BZ2vDDbBp0ccY45QaLJyHh7');
  const AesIV = CryptoJS.enc.Utf8.parse('bvh7fXJNe2lPw3ti');

  const cipher = CryptoJS.AES.decrypt(payload, AesKey, {
    iv: AesIV,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  const jsonString = cipher.toString(CryptoJS.enc.Utf8);
  return JSON.parse(jsonString);
}
