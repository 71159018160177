import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { useLoginStore, useSettingsStore } from 'src/stores';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
let api: AxiosInstance;
//const $axios = axios;

export default boot(({ app, router, store }) => {
  const settingsStore = useSettingsStore(store);
  api = axios.create({ baseURL: settingsStore.baseGraphUrl });

  // token is because we were loaded from the Portal app
  if (settingsStore.token) {
    api.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        // use getters to retrieve the access token from vuex
        // store
        const token = settingsStore.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  } else {
    const loginStore = useLoginStore();

    api.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        // use getters to retrieve the access token from vuex
        // store
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      // remember to make this async as the store action will
      // need to be awaited
      async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          originalRequest.url.includes('api/auth/refreshToken')
        ) {
          loginStore.logout();
          router.push({ path: '/login', replace: true });
          return Promise.reject(error);
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          // await execution of the store async action before
          // return
          const refresh_token = localStorage.getItem('refreshToken');

          const response = await axios.get<{
            access_token: string;
            token_type: string;
            expires_in: number;
          }>(`${settingsStore.baseGraphUrl}/api/auth/refreshToken`, {
            params: { refresh_token: refresh_token },
          });

          localStorage.setItem('accessToken', response.data.access_token);

          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  }

  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
