/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { api } from 'boot/axios';
import { performStoreReset, useSettingsStore } from 'src/stores';
import axios from 'axios';

export interface AccessToken {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export interface UserProfile {
  id: string;
  institutionId: number;
  memberOf: string[];
  userid: number;
  username: string;
}

export const useLoginStore = defineStore('login', () => {
  async function login(username: string, password: string) {
    const settingsStore = useSettingsStore();

    const response = await axios.get<AccessToken>(
      `${settingsStore.baseGraphUrl}/api/auth/accessToken`,
      {
        params: { username: username, password: password },
      }
    );

    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);

    const response1 = await api.get<UserProfile>('/api/auth/profile', {
      params: { access_token: getAccessToken() },
    });

    setUserProfile(response1.data);
  }

  function isLoggedIn(): boolean {
    return !!getAccessToken();
  }

  function setUserProfile(userProfile: UserProfile) {
    localStorage.setItem('userProfile', JSON.stringify(userProfile));
  }

  function getUserProfile(): UserProfile | null {
    const json = localStorage.getItem('userProfile');
    if (!json) return null;
    return JSON.parse(json);
  }

  function getAccessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // function getRefreshToken(): string {
  //   return localStorage.getItem('refreshToken') ?? '';
  // }

  function setAccessToken(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  }

  function setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  function logout() {
    performStoreReset();
    localStorage.clear();
    // router.push({ path: '/login', replace: true });
  }

  return { login, logout, getAccessToken, getUserProfile, isLoggedIn };
});
