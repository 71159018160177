import { defineStore } from 'pinia';
import { IUsableColumnType } from './models';
import { ReportsGraphqlClient } from 'src/services/graphqlClients';
import { ReportsDataPointSourceEnum } from 'src/gql/graphql';
import { ref } from 'vue';

export const useUsableColumnTypesStore = defineStore(
  'usableColumnTypes',
  () => {
    const usableColumnTypes = ref(<IUsableColumnType[]>[]);
    const fetching = ref(false);
    const users = ref(<IUsableColumnType[]>[]);
    const immunities = ref(<IUsableColumnType[]>[]);
    const documents = ref(<IUsableColumnType[]>[]);
    const programs = ref(<IUsableColumnType[]>[]);
    const tbs = ref(<IUsableColumnType[]>[]);
    const tbQuestionnaires = ref(<IUsableColumnType[]>[]);
    const titers = ref(<IUsableColumnType[]>[]);
    const tags = ref(<IUsableColumnType[]>[]);
    const insurances = ref(<IUsableColumnType[]>[]);
    const medicalIds = ref(<IUsableColumnType[]>[]);
    const tokens = ref(<IUsableColumnType[]>[]);

    function reset() {
      usableColumnTypes.value.splice(0);
      users.value.splice(0);
      immunities.value.splice(0);
      documents.value.splice(0);
      programs.value.splice(0);
      tbs.value = tbs.value.splice(0);
      tbQuestionnaires.value.splice(0);
      titers.value.splice(0);
      tags.value.splice(0);
      insurances.value.splice(0);
      medicalIds.value.splice(0);
      tokens.value.splice(0);
      fetching.value = false;
    }

    function visibleFilter(text: string) {
      if (text === '') {
        clearVisibleFiltered();
      } else {
        const lt = text.toLowerCase();

        usableColumnTypes.value.forEach((column) => {
          column.visible = column.name.toLowerCase().includes(lt);
        });
      }
    }

    function clearVisibleFiltered() {
      usableColumnTypes.value.forEach((column) => {
        column.visible = true;
      });
    }

    // function sortColumns() {
    //   usableColumnTypes.value.sort((a, b) => {
    //     if (a.source === b.source) {
    //       return a.name.localeCompare(b.name);
    //     } else {
    //       return a.source.localeCompare(b.source);
    //     }
    //   });
    // }

    async function fetchUsableColumnTypes() {
      fetching.value = true;

      try {
        usableColumnTypes.value.splice(0);
        users.value.splice(0);
        immunities.value.splice(0);
        documents.value.splice(0);
        programs.value.splice(0);
        tbs.value.splice(0);
        tbQuestionnaires.value.splice(0);
        titers.value.splice(0);
        tags.value.splice(0);
        insurances.value.splice(0);
        medicalIds.value.splice(0);
        tokens.value.splice(0);

        const graphqlClient = new ReportsGraphqlClient();
        const dataPoints = await graphqlClient.dataPoints();

        for (const t of dataPoints) {
          usableColumnTypes.value.push({
            computed: t.computed,
            dateFormat: t.dateFormat,
            displayName: t.displayName,
            hidden: t.hidden,
            name: t.name,
            description: t.description,
            type: t.type,
            visible: true,
            source: t.source,
            removed: false,
          });
        }

        usableColumnTypes.value.forEach((dt) => {
          switch (dt.source) {
            case ReportsDataPointSourceEnum.Documents:
              documents.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Immunities:
              immunities.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Programs:
              programs.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Tags:
              tags.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Tb:
              tbs.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.TbQuestionnaires:
              tbQuestionnaires.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Titers:
              titers.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.User:
              users.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Insurance:
              insurances.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.MedicalId:
              medicalIds.value.push(dt);
              break;
            case ReportsDataPointSourceEnum.Tokens:
              tokens.value.push(dt);
              break;
          }
        });
      } finally {
        fetching.value = false;
      }
    }

    return {
      usableColumnTypes,
      fetching,
      users,
      immunities,
      documents,
      programs,
      tbs,
      tbQuestionnaires,
      titers,
      tags,
      insurances,
      medicalIds,
      tokens,
      reset,
      visibleFilter,
      clearVisibleFiltered,
      fetchUsableColumnTypes,
    };
  },
);
