import { gql } from '@urql/core';
import {
  Maybe,
  Query,
  ReportHeader,
  ReportsSaveResult,
  ReportDataSetResult,
  ReportDataPointDefinition,
  ReportDefinition,
  ReportDataSetInput,
  ReportDefinitionInput,
  ReportSchedule,
  ReportScheduleUpdateInput,
} from 'src/gql/graphql';
import { gqlClient } from 'boot/graphql';

export class ReportsGraphqlClient {
  async dataPoints(
    institutionId?: number,
  ): Promise<ReportDataPointDefinition[]> {
    const dataPointsQuery = gql`
      query ReportDataPoints($institutionId: Int) {
        institution(id: $institutionId) {
          reports {
            dataPoints {
              source
              type
              name
              description
            }
          }
        }
      }
    `;
    const graphResponse = await gqlClient
      .query<Query>(dataPointsQuery, { institutionId })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.institution?.reports?.dataPoints ?? [];
  }

  async headers(institutionId?: number): Promise<ReportHeader[]> {
    const headersQuery = gql`
      query ReportHeaders($institutionId: Int) {
        institution(id: $institutionId) {
          reports {
            list {
              name
            }
          }
        }
      }
    `;

    const graphResponse = await gqlClient
      .query<Query>(headersQuery, { institutionId })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.institution?.reports?.list ?? [];
  }

  async definition(name: string): Promise<Maybe<ReportDefinition> | undefined> {
    const reportDefinitionQuery = gql`
      query Definition($name: String!) {
        institution {
          reports {
            definition(name: $name) {
              apiVersion
              institutionId
              name
              dateFormat
              dataPoints {
                name
                displayName
                hidden
              }
              requirementGroup
              term
              verifiedOnly
              onFetch
              logLevel
              orderBy
              unpivotDataPoints {
                keyName
                valueName
                dataPointNames
              }
              schedule {
                cron
                enabled
              }
            }
          }
        }
      }
    `;

    const graphResponse = await gqlClient
      .query<Query>(reportDefinitionQuery, {
        name,
      })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.institution?.reports?.definition;
  }

  async adHoc(
    input: ReportDataSetInput,
  ): Promise<ReportDataSetResult | undefined> {
    const adHocQuery = gql`
      query AdHocQuery($input: ReportDataSetInput!) {
        institution {
          reports {
            dataSet(input: $input) {
              count
              dataSet
              logs
            }
          }
        }
      }
    `;

    const graphResponse = await gqlClient
      .query<Query>(adHocQuery, {
        input,
      })
      .toPromise();

    if (graphResponse.error) {
      console.error(graphResponse.error);
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.institution?.reports?.dataSet;
  }

  async save(input: ReportDefinitionInput): Promise<string | undefined> {
    const saveMutation = gql`
      mutation ReportsSave($input: ReportDefinitionInput!) {
        reportsSave(input: $input) {
          id
        }
      }
    `;

    const graphResponse = await gqlClient
      .mutation<ReportsSaveResult>(saveMutation, {
        input,
      })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.id;
  }

  async delete(name: string): Promise<boolean> {
    const deleteMutation = gql`
      mutation ReportsDelete($name: String!) {
        reportsDelete(name: $name)
      }
    `;

    const graphResponse = await gqlClient
      .mutation<boolean>(deleteMutation, {
        name,
      })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data ?? false;
  }

  async schedule(name: string): Promise<ReportSchedule | undefined> {
    const scheduleQuery = gql`
      query Schedule($name: String!) {
        institution {
          reports {
            schedule(name: $name) {
              cron
              enabled
              filenameTemplate
              delimiter
              removeHeader
              preventEmptyFiles
            }
          }
        }
      }
    `;

    const graphResponse = await gqlClient
      .query<Query>(scheduleQuery, {
        name,
      })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data?.institution?.reports?.schedule ?? undefined;
  }

  async updateSchedule(input: ReportScheduleUpdateInput): Promise<boolean> {
    const updateScheduleUpdateMutation = gql`
      mutation ReportsScheduleUpdate($input: ReportScheduleUpdateInput!) {
        reportsScheduleUpdate(input: $input)
      }
    `;

    const graphResponse = await gqlClient
      .mutation<boolean>(updateScheduleUpdateMutation, {
        input,
      })
      .toPromise();

    if (graphResponse.error) {
      throw new Error('error from graphql', { cause: graphResponse.error });
    }

    return graphResponse.data ?? false;
  }
}
