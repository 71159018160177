import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { IColumn } from './models';
import {
  useSettingsStore,
  useLoginStore,
  usePersistenceStore,
} from 'src/stores';
import {
  InputMaybe,
  ReportDataPointInput,
  ReportDataSetInput,
  ReportDataSetResult,
  ReportDefinitionInput,
  ReportSchedule,
  ReportsDataSetFormatEnum,
  ReportsDateFormatEnum,
  ReportUnpivot,
  ReportUnpivotInput,
} from 'src/gql/graphql';
import { ReportsGraphqlClient } from 'src/services/graphqlClients';
import { api } from 'src/boot/axios';
import { ref } from 'vue';

export const useReportStore = defineStore('report', () => {
  const name = ref('');
  const dateFormat = ref(ReportsDateFormatEnum.Usa);
  const columns = ref(<IColumn[]>[]);
  const rowsNumber = ref(0);
  const rows = ref(<{ [key: string]: string | DateTime }[]>[]);
  const rowsPerPage = ref(5);
  const page = ref(1);
  const requirementGroup = ref(<string | undefined>undefined);
  const term = ref(<string | undefined>undefined);
  const verifiedOnly = ref(false);
  const visibleColumns = ref(<string[]>[]); // the Q-Table wants a list of visible columns, so we can hide RowId
  const loading = ref(false);
  const originalOnFetchScript = ref('');
  const modifiedOnFetchScript = ref('');
  const orderBy = ref(<string[] | undefined>undefined);
  const unpivotDataPoints = ref(<ReportUnpivot | undefined>undefined);

  const persistenceColumnsStore = usePersistenceStore();
  const loginStore = useLoginStore();
  const settingsStore = useSettingsStore();
  const schedule = ref(<ReportSchedule | undefined>undefined);

  function reset() {
    name.value = '';
    dateFormat.value = ReportsDateFormatEnum.Usa;
    columns.value.splice(0);
    rows.value.splice(0);
    loading.value = false;
    originalOnFetchScript.value = '';
    modifiedOnFetchScript.value = '';
    rowsNumber.value = 0;
    rowsPerPage.value = 5;
    page.value = 1;
    requirementGroup.value = undefined;
    term.value = undefined;
    verifiedOnly.value = false;
    visibleColumns.value.splice(0);
    orderBy.value = undefined;
    unpivotDataPoints.value = undefined;
    schedule.value = undefined;
  }

  async function getDownloadAdHocLink(): Promise<string> {
    const columns: IColumn[] = [];

    persistenceColumnsStore.columns.forEach((i) => {
      columns.push(<IColumn>(<any>i));
    });

    const body = {
      institutionId: settingsStore.institutionId ?? 0,
      dateFormat: dateFormat.value,
      dataPoints: columns,
      requirementGroup: requirementGroup.value ?? '',
      term: term.value ?? '',
      verifiedOnly: verifiedOnly.value,
      onFetch: modifiedOnFetchScript.value,
      unpivotDataPoints: unpivotDataPoints.value,
      orderBy: orderBy.value,
      name: name.value,
    };

    interface RedirectResponse {
      Location?: string;
      k?: string;
    }

    try {
      const token = settingsStore.isStandalone()
        ? loginStore.getAccessToken()
        : settingsStore.token;

      const response = await api.post<RedirectResponse>(
        `${settingsStore.baseGraphUrl}/api/report/prepareDataDownload`,
        body,
      );

      return `${settingsStore.baseGraphUrl}/api/report?t=${encodeURIComponent(
        token,
      )}&k=${encodeURIComponent(response.data.k ?? '')}`;
    } finally {
      loading.value = false;
    }
  }

  async function getAdHocDataFromTable(
    page: number,
    pageSize: number,
  ): Promise<ReportDataSetResult | undefined> {
    loading.value = true;

    const adHocInput: ReportDataSetInput = <ReportDataSetInput>{};
    adHocInput.dataSetFormat = ReportsDataSetFormatEnum.Properties;
    adHocInput.name = undefined;
    adHocInput.page = page;
    adHocInput.pageSize = pageSize;

    adHocInput.definition = <ReportDefinitionInput>{};
    adHocInput.definition.apiVersion = '1.0';
    adHocInput.definition.institutionId = settingsStore.institutionId;
    adHocInput.definition.dateFormat = <ReportsDateFormatEnum>(
      (dateFormat.value?.toUpperCase() ?? 'USA')
    );
    adHocInput.definition.logLevel = 'INFO';
    adHocInput.definition.name = 'adhoc';
    adHocInput.definition.onFetch = modifiedOnFetchScript.value;
    adHocInput.definition.requirementGroup = requirementGroup.value ?? '';
    adHocInput.definition.term = term.value ?? '';
    adHocInput.definition.verifiedOnly = verifiedOnly.value;
    adHocInput.definition.orderBy = orderBy.value;
    adHocInput.definition.unpivotDataPoints = <
      InputMaybe<ReportUnpivotInput> | undefined
    >(<unknown>unpivotDataPoints.value);

    adHocInput.definition.dataPoints = [];

    persistenceColumnsStore.columns.forEach((c) => {
      const dataPoint: ReportDataPointInput = {
        displayName: c.displayName,
        hidden: c.hidden,
        name: c.name,
      };
      adHocInput.definition?.dataPoints.push(dataPoint);
    });

    let data: ReportDataSetResult | undefined;

    try {
      const gqlClient = new ReportsGraphqlClient();
      data = await gqlClient.adHoc(adHocInput);

      if (data?.logs) {
        data?.logs.forEach((log) => {
          console.log(`script log: ${log}`);
        });
      }
    } finally {
      loading.value = false;
    }

    return data;
  }

  async function rebuildTable() {
    const newColumns: IColumn[] = [];
    const newRows: { [key: string]: string | DateTime }[] = [];
    visibleColumns.value.length = 0;

    if (persistenceColumnsStore.columns.length > 0) {
      const data = await getAdHocDataFromTable(page.value, rowsPerPage.value);
      rowsNumber.value = data?.count ?? 0;

      newColumns.push({
        name: 'rowId',
        label: 'RowId',
        align: 'left',
        field: 'RowId',
        sortable: false,
        required: false,
      });

      const displayNameLookup: { [k: string]: string } = {};

      persistenceColumnsStore.columns.forEach((i) => {
        let label = `${i.displayName} (${i.name})`;

        if (i.displayName == undefined || i.displayName === '') {
          label = i.name;
          displayNameLookup[i.name] = i.name;
        } else {
          displayNameLookup[i.displayName] = i.name;
        }

        const newColumn: IColumn = {
          name: i.name,
          label: label,
          align: 'left',
          field: i.name,
          sortable: false,
          sort: (a, b) => a - b,
        };

        newColumns.push(newColumn);
      });

      let rowCount = 0;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data?.dataSet?.forEach((dataRow) => {
        dataRow['RowId'] = `${rowCount}`;

        const row: { [key: string]: string | DateTime } = {};

        for (const fieldName in dataRow) {
          let displayNameLookupValue = displayNameLookup[fieldName];

          if (displayNameLookupValue == undefined) {
            const newColumn: IColumn = {
              name: fieldName,
              label: fieldName,
              align: 'left',
              field: fieldName,
              sortable: false,
              sort: (a, b) => a - b,
            };

            newColumns.push(newColumn);
            displayNameLookup[fieldName] = fieldName;
            displayNameLookupValue = fieldName;
          }

          row[displayNameLookupValue] = dataRow[fieldName] ?? '';

          if (fieldName !== 'RowId') {
            visibleColumns.value.push(displayNameLookupValue);
          }
        }

        rowCount++;
        newRows.push(row);
      });
    }

    columns.value = newColumns;
    rows.value = newRows;
  }

  return {
    name,
    dateFormat,
    columns,
    rows,
    rowsNumber,
    requirementGroup,
    term,
    verifiedOnly,
    visibleColumns,
    loading,
    originalOnFetchScript,
    modifiedOnFetchScript,
    reset,
    getDownloadAdHocLink,
    rebuildTable,
    rowsPerPage,
    page,
    orderBy,
    unpivotDataPoints,
    schedule,
  };
});
